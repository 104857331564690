<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='Overview' />
      <div class='mb-16'>
        <h1 class='text-2xl font-semibold mb-4'>Congress Overview</h1>
          <div class='px-4 py-8 rounded' style='background-color: #FAFAFA;'>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Title
              </div>
              <div class='text-sm'>
                The 26th Congress of the Asian Pacific Society of Respirology (APSR 2022)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Thema
              </div>
              <div class='text-sm'>
                Above and Beyond
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Date
              </div>
              <div class='text-sm'>
                17-20 November 2022
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Venue
              </div>
              <div class='text-sm'>
                Coex, Seoul, Korea
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Hosted by
              </div>
              <div class='text-sm'>
                 <p> <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/website/APSR.png' class='inline-block my-2 mr-2 h-16' />Asian Pacific Society of Respirology (APSR)
                 </p>
                 <p><img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/website/KATRD.png' class='inline-block my-2 mr-2 h-16' />The Korean Academy of Tuberculosis and Respiratory Diseases (KATRD)
                 </p>
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Sponsored by
              </div>
              <div class='text-sm'>
                 <p><img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/website/KTO_icon.png' class='inline-block my-2 mr-2 h-16' />Korea Tourism Organization
                 </p>
                 <p><img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/website/SMG_icon.png' class='inline-block my-2 mr-2 h-16' />Seoul Metropolitan Government
                 </p>
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Expected Participants
              </div>
              <div class='text-sm'>
                3,000 Delegates from 50 Countries
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Official Language
              </div>
              <div class='text-sm'>
                English
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Program
              </div>
              <div class='text-sm'>
                Lecture, Symposium, Oral and Poster Discussion, Sponsored Symposium, Post-graduate Course, Opening/Closing Ceremony, Welcome Reception, Gala Dinner, Social Program, Tour Program, Exhibition, etc.
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Website
              </div>
              <div class='text-sm'>
                www.apsr2022.org
              </div>
            </div>
          </div>
      </div>
      <div class='mb-16'>
        <h1 class='text-2xl font-semibold mb-4'>Key dates</h1>
        <div class="flex flex-row items-start gap-x-4">
          <div class='w-1/2 px-4 py-8 rounded h-full' style='background-color: #FAFAFA;'>
            <h2 class='text-lg font-normal text-center'>Registration</h2>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [Physical] Early-bird Registration
              </div>
              <div class='text-sm'>
                Until 16 Sep 2022<br>(11:59pm KST/UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [Physical] Standard Registration
              </div>
              <div class='text-sm'>
                17 Sep – 4 Nov 2022<br>(11:59pm KST/UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [Physical] Onsite Registration
              </div>
              <div class='text-sm'>
                17 Nov – 20 Nov 2022
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [On-demand Platform] Early-bird
              </div>
              <div class='text-sm'>
                Until 16 Sep 2022<br>(11:59pm KST/UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [On-demand Platform] Standard
              </div>
              <div class='text-sm'>
                17 Sep – 11 Dec 2022<br>(11:59pm KST/UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                [On-demand Platform] After Standard
              </div>
              <div class='text-sm'>
                12 Dec  2022 onwards
              </div>
            </div>
          </div>
          <div class='w-1/2 px-4 py-8 rounded h-full' style='background-color: #FAFAFA;'>
            <h2 class='text-lg font-normal text-center'>Abstract Submission</h2>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Abstracts Submission Opens
              </div>
              <div class='text-sm'>
                10 March 2022<br>(Noon KST/UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Extended Abstract Submission Deadline
              </div>
              <div class='text-sm'>
                14 June 2022<br>(Noon KST UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Notification of Abstract Acceptance
              </div>
              <div class='text-sm'>
                by the end of August 2022
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Late-breaking Abstract Submission Open
              </div>
              <div class='text-sm'>
                8 August 2022<br>(Noon KST / UTC+9)
              </div>
            </div>
            <div class='my-6 flex flex-col lg:flex-row items-start lg:items-center gap-x-16'>
              <div 
                class='font-semibold w-full lg:w-36 flex-shrink-0'
                :style='themeTextColorStyle'>
                Late-breaking Abstract Submission Deadline
              </div>
              <div class='text-sm'>
                22 August 2022<br>(Noon KST / UTC+9)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 class='text-2xl font-semibold mb-4'>Local Congress Secretariat of APSR 2022</h1>
        <div class='py-4 pl-4 lg:pl-0 pr-4 rounded flex flex-row items-center justify-between gap-x-4 lg:gap-x-8 border'>
          <img src='https://apsr2022.org/img/sub/coex1.png'
            class='w-1/3 py-4 lg:px-6' />
          <div class='w-2/3 p-2'>
            <img src="https://apsr2022.org/img/sub/coexLogo.png" alt=""/>
            <div class='my-4 flex flex-col lg:flex-row items-start lg:items-center justify-center'>
              <div class='font-semibold w-36 flex-shrink-0' :style='themeTextColorStyle'>
                Address
              </div>
              <div>
                513, Yeongdong-daero, Gangnam-gu, Seoul 06164, Korea
              </div>
            </div>
            <div class='mb-4 flex flex-col lg:flex-row items-start lg:items-center'>
              <div class='font-semibold w-36 flex-shrink-0' :style='themeTextColorStyle'>
                Fax
              </div>
              <div>
                <a href='mailto:khrs@k-hrs.org'>+82-2-6944-8306</a>
              </div>
            </div>
            <div class=' flex flex-col lg:flex-row items-start lg:items-center'>
              <div class='font-semibold w-36 flex-shrink-0' :style='themeTextColorStyle'>
                Fax
              </div>
              <div>
                <a href='mailto:khrs@k-hrs.org'>info@apsr2022.org</a>
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'Overview',
  components: {
    Apsr2022AboutPageNavigation,
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle'
    ]),
  },
}
</script>
